import React, { useState } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Form, Input, Typography, Space, Image } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { editPass, login } from '../../redux/Reducers/authReducer'
import { getAuthResponseMessage, getDefaultPassDetected, getEditDefaultPassResponseMessage, getLoginSelector } from '../../redux/Selectors/authSelectors'
import authImage from '../../img/authImage.png'
import pskLogo from '../../img/pskLogo.svg'
import { getCompanyName } from '../../redux/Selectors/appSelectors'
import defaultAuthImage from '../../img/defaultAuthImage.jpg'

const { Text, Title } = Typography

export const Login: React.FC = () => {
    const authResponseMessage = useSelector(getAuthResponseMessage)
    const editDefaultPassResponseMessage = useSelector(getEditDefaultPassResponseMessage)
    const defaultPassDetected = useSelector(getDefaultPassDetected)
    const userLogin = useSelector(getLoginSelector)
    const companyName = useSelector(getCompanyName)
    const dispatch: AppDispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    let isCompanyPskPharma = false

    companyName === 'pskPharma' && (isCompanyPskPharma = true)


    const onFinish = async (values: any) => {
        setLoading(true)
        await dispatch(login(values.username, values.password, values.rememberMe))
        setLoading(false)
    }

    const handleEditPass = async (data: any) => {
        setLoading(true)
        await dispatch(editPass(userLogin, '12345678', data.confirmPassword))
        setLoading(false)
    }

    const passwordRules = [
        {
            required: true,
            message: 'Пожалуйста, введите новый пароль!',
        },
        {
            min: 8,
            message: 'Пароль должен содержать минимум 8 символов!',
        },
        {
            pattern: /[A-Z]/,
            message: 'Пароль должен содержать минимум одну заглавную букву!',
        },
        {
            pattern: /\d/,
            message: 'Пароль должен содержать минимум одну цифру!',
        },
        {
            pattern: /[!@#$%^&*(),.?":{}|<>]/,
            message: 'Пароль должен содержать минимум один спецсимвол!',
        },
        {
            pattern: /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/,
            message: 'Пароль может содержать только латинские буквы, цифры и специальные символы.',
        },
    ]

    const validatePasswords = (_: any, value: any) => {
        if (!value || form.getFieldValue('newPassword') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Пароли не совпадают!'));
    }

    const [form] = Form.useForm()

    return <>
        {isCompanyPskPharma && <Image className='pskLogo' src={pskLogo} style={{ position: 'absolute', top: '-40px', left: '30px', width: '200px', height: '200px', filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, .6))' }} />}
        <Space style={{ display: 'grid', placeItems: 'center', height: '100vh', backgroundImage: `url(${isCompanyPskPharma ? authImage : defaultAuthImage})`, backgroundPosition: 'center' }} >
            <div style={{ position: 'relative', bottom: '50px' }}>
                <Text type='success' style={{ fontSize: '26pt', textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>V</Text>
                <Text style={{ fontSize: '18pt', display: 'inline-block', marginBottom: '70px', textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)', color: 'rgb(255 255 255 / 85%)' }}>alidControl - современная система управления валидацией</Text>
                <Card
                    style={{ minWidth: '300px', backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0, 0, 0, 0.2)', opacity: '0.7', borderColor: 'rgba(0, 0, 0, 0.3)' }}
                    title={defaultPassDetected ? <Text type='warning' style={{ fontSize: '12pt' }}>{authResponseMessage}</Text> : editDefaultPassResponseMessage ? <Text type='success' style={{ fontSize: '12pt' }}>{editDefaultPassResponseMessage}</Text> : <Text type='success' style={{ color: 'rgb(255 255 255 / 85%)' }}>Для продолжения, авторизуйтесь</Text>}
                >
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={defaultPassDetected ? handleEditPass : onFinish}
                        form={form}
                        autoComplete='on'
                        style={{ marginBottom: '0', paddingBottom: '0' }}
                    >
                        {!defaultPassDetected && authResponseMessage && <Title type={'danger'} level={5} style={authResponseMessage ? { display: 'inline-block', marginBottom: '10px', marginTop: '0px' } : {}}>{authResponseMessage}</Title>}
                        {!defaultPassDetected ?
                            <>
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Пожалуйста, введите имя пользователя!' }]}
                                    style={{ marginTop: '0' }}
                                >
                                    <Input autoFocus prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Имя пользователя" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Пароль"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                                        <Checkbox style={{ color: 'rgb(255 255 255 / 85%)' }}>Запомнить меня</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: '0' }}>
                                    <Button type="primary" htmlType="submit" loading={loading}>Войти</Button>
                                </Form.Item>
                            </> :
                            <>
                                <Form.Item
                                    name="newPassword"
                                    rules={passwordRules}
                                    hasFeedback
                                >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text>Новый пароль</Text>
                                        <Input.Password type="password" />
                                    </Space>
                                </Form.Item>

                                <Form.Item
                                    name="confirmPassword"
                                    rules={[
                                        { required: true, message: 'Пожалуйста, повторите новый пароль!' },
                                        { validator: validatePasswords },
                                    ]}
                                    hasFeedback
                                >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text>Подтверждение</Text>
                                        <Input.Password type="password" />
                                    </Space>
                                </Form.Item>

                                <Form.Item style={{ marginTop: '30px', marginBottom: '0px' }} >
                                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Button type={"primary"} htmlType="submit">Изменить пароль</Button>
                                    </Space>
                                </Form.Item>
                            </>
                        }
                    </Form>
                </Card>
            </div>
        </Space >
    </>
}