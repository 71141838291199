import { NewPremObjectType } from "../redux/Reducers/premisesReducer"
import { premInstance as inst } from "./instance"

export const premisesAPI = {
    getPremises() {
        return inst.get(`getPremList.php`).then(res => res.data)
    },
    getReestrData(id: string) {
        return inst.get(`getReestrData.php?id=${id}&prem`).then(res => res.data)
    },
    uploadMainPhoto(id: string, file: any) {
        let formData = new FormData()
        formData.append("image", file)
        return inst.post(`uploadMainPhoto.php?id=${id}&table=premises`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deleteMainPhoto(id: string) {
        return inst.get(`deleteMainPhoto.php?id=${id}&table=premises`).then(res => res.data)
    },
    updateDescription(id: string,
        nomer?: string,
        name?: string,
        premClass?: string,
        department?: string,
        VMPDepartment?: string,
        interval?: string,
        mode?: string
    ) {
        let data = new FormData()
        data.append("id", id)
        data.append("table", 'premises')
        nomer !== undefined && data.append("nomer", nomer)
        name !== undefined && data.append("name", name)
        premClass !== undefined && data.append("class", premClass)
        department !== undefined && data.append("department", department)
        VMPDepartment !== undefined && data.append("VMPDepartment", VMPDepartment)
        interval !== undefined && data.append("interval", interval)
        mode !== undefined && data.append("mode", mode)

        return inst.post(`updateDescription.php`, data, {}).then(res => res.data)
    },
    getTechnicalInfo(id: string) {
        return inst.get(`getTechnicalInfo.php?id=${id}`).then(res => res.data)
    },
    updateTechnicalInfo(id: string, techType: string, text: string) {
        let data = new FormData()
        data.append("id", id)
        data.append("techType", techType)
        data.append("text", text)
        return inst.post(`updateTechnicalInfo.php`, data).then(res => res.data)
    },
    getPhotos(id: string) {
        let data = new FormData()
        data.append("id", id)
        return inst.post(`getPhotos.php`, data).then(res => res.data)
    },
    getCleanPremList(id: string) {
        let data = new FormData()
        data.append("id", id)
        return inst.post(`getCleanPremList.php`, data).then(res => res.data)
    },
    getCleanGroupLabels(premId: string) {
        let data = new FormData()
        data.append("premId", premId)
        return inst.post(`getCleanGroupLabels.php`, data).then(res => res.data)
    },
    uploadPhotos(id: string, files: any) {
        let formData = new FormData()
        formData.append("id", id)
        formData.append("table", "premises")
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append("image[]", file)
            }
        } else {
            formData.append("image[]", files)
        }
        return inst.post(`uploadPhotos.php`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deletePhoto(id: string, photoId: string) {
        let formData = new FormData()
        formData.append("id", id)
        formData.append("photoId", photoId)
        return inst.post(`deletePhoto.php`, formData, {}).then(res => res.data)
    },
    updatePdfDescription(photoId: string, text: string, id: string) {
        let data = new FormData()
        data.append("photoId", photoId)
        data.append("text", text)
        data.append("id", id)
        return inst.post(`updatePdfDescription.php`, data, {}).then(res => res.data)
    },
    updateReestrDate(id: string, premId: string, date: string, dateType: 'dvo' | 'dvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("premId", premId)
        data.append("date", date)
        data.append("dateType", dateType)
        return inst.post(`updateReestrDate.php`, data, {}).then(res => res.data)
    },
    updateReestrDateTask(id: string, premId: string, date: string, dateType: 'dvo' | 'dvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("premId", premId)
        data.append("date", date)
        data.append("dateType", dateType)
        return inst.post(`updateReestrDateTask.php`, data, {}).then(res => res.data)
    },
    updateReestrDocsCode(id: string, recordId: string, text: string, dataType: 'nvo' | 'nvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("recordId", recordId)
        data.append("text", text)
        data.append("dataType", dataType)
        return inst.post(`updateReestrDocsCode.php`, data, {}).then(res => res.data)
    },
    updateReestrDocsCodeTask(id: string, recordId: string, text: string, dataType: 'nvo' | 'nvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("recordId", recordId)
        data.append("text", text)
        data.append("dataType", dataType)
        return inst.post(`updateReestrDocsCodeTask.php`, data, {}).then(res => res.data)
    },
    updateCleanPremItemData(premId: string, recordId: string, text: string, dataType: 'sp' | 'nomer' | 'name') {
        let data = new FormData()
        data.append("premId", premId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("text", text)
        return inst.post(`updateCleanPremItemData.php`, data, {}).then(res => res.data)
    },
    uploadDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', file: any) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("file", file)
        return inst.post(`uploadDocument.php`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    uploadTaskDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', file: any) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("file", file)
        return inst.post(`uploadTaskDocument.php`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deleteDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', url: string) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("url", url)
        return inst.post(`deleteDocument.php`, data, {}).then(res => res.data)
    },
    createCleanPrem(cleanTab: string, nomer: string, sp: string, name: string) {
        let data = new FormData()
        data.append("cleanTab", cleanTab)
        data.append("nomer", nomer)
        data.append("sp", sp)
        data.append("name", name)
        return inst.post(`createCleanPrem.php`, data, {}).then(res => res.data)
    },
    deleteCleanPrem(cleanTab: string, id: string) {
        let data = new FormData()
        data.append("cleanTab", cleanTab)
        data.append("id", id)
        return inst.post(`deleteCleanPrem.php`, data, {}).then(res => res.data)
    },
    deleteCleanPremGroup(cleanTab: string, groupId: string) {
        let data = new FormData()
        data.append("cleanTab", cleanTab)
        data.append("groupId", groupId)
        return inst.post(`deleteCleanPremGroup.php`, data, {}).then(res => res.data)
    },
    createCleanPremGroup(cleanTab: string, dataItems: Array<string>, count: string) {
        return inst.post(`createCleanPremGroup.php`, { cleanTab, dataItems, count }, {}).then(res => res.data)
    },
    editCleanPremGroup(cleanTab: string, dataItems: Array<string>, count: string, groupId: string) {
        return inst.post(`editCleanPremGroup.php`, { cleanTab, dataItems, count, groupId }, {}).then(res => res.data)
    },
    getCurrentPremData(myPremDataIdArray: Array<string>) {
        return inst.post(`getCurrentPremData.php`, { myPremDataIdArray }, {}).then(res => res.data)
    },
    updatePremWorkData(recordId: string, changeParam: 'et' | 'season' | 'pam2' | 'isCardUpdated', text: string) {
        return inst.post(`updatePremWorkData.php`, { recordId, changeParam, text }, {}).then(res => res.data)
    },
    createNewObject(data: NewPremObjectType) {
        return inst.post(`createNewObject.php`, data, {}).then(res => res.data)
    },
}