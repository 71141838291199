import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../redux/store"
import { getFinishedTasks } from "../../redux/Selectors/appSelectors"
import React, { useEffect } from "react"
import { getFinishedTasksCount } from "../../redux/Reducers/appReducer"
import { NotificationInstance } from "antd/es/notification/interface"

interface props {
    api: NotificationInstance
}

export const FinishedTasksNotification: React.FC<props> = ({api}) => {
    const dispatch: AppDispatch = useDispatch()
    const finishedTasks = useSelector(getFinishedTasks)

    useEffect(() => {
        setTimeout(() => dispatch(getFinishedTasksCount()), 50)

        const interval = setInterval(() => {
            dispatch(getFinishedTasksCount());
        }, 60000)

        return () => clearInterval(interval);
    }, [])

    const openNotificationFinishedTasks = () => {
        api['success']({
            message: 'Выполненные задачи',
            description: 'Имеются выполненные задачи (' + finishedTasks + ' шт.)',
            duration: null
        })
    }

    useEffect(() => {
       finishedTasks > 0 && openNotificationFinishedTasks()
    }, [finishedTasks])

    return null
}