import { authInstance as inst } from './instance'

export const authAPI = {
	login(userName: string, password: string, rememberMe: boolean) {
		return inst
			.post(`loginApp.php`, { userName, password, rememberMe }, {})
			.then(res => res.data)
	},
	editPass(userName: string, oldPassword: string, newPassword: string) {
		return inst
			.post(`editPass.php`, { userName, oldPassword, newPassword }, {})
			.then(res => res.data)
	}
}
