import image from '../img/page7.webp'
import image2 from '../img/page6.webp'

const Page7 = ({ secondAnswer }) => {
	const capitalizeFirstLetter = string => {
		if (!string) return ''
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	return (
		<>
			{secondAnswer.toLowerCase() === 'спина' ? (
				<>
					<img src={image2} style={{ width: '100%' }} />
					<p>
						Люкс нахмурил лоб и, немного подумав, ответил: «<em>Cпина!</em>»
						<p style={{ margin: '0px' }}>
							«<em>Ха-ха, ты угадал! Правильный ответ — это твоя спина!</em>» —
							рассмеялся Снеговик. — «
							<em>
								Ты молодец, поэтому я пропущу тебя! Но будь осторожен, впереди
								тебя ждёт ёлка, которая не даст себя украсить просто так!
							</em>
							»
						</p>
					</p>
				</>
			) : (
				<>
					<img src={image} style={{ width: '100%' }} />
					<p>
						Люкс нахмурил лоб и, немного подумав, ответил: «
						<em>{capitalizeFirstLetter(secondAnswer)}!</em>» «
						<em>Ха-ха, почти! Но правильный ответ — это твоя спина!</em>» —
						рассмеялся Снеговик. — «
						<em>
							Ты всё равно молодец, поэтому я пропущу тебя! Но будь осторожен,
							впереди тебя ждёт ёлка, которая не даст себя украсить просто так!
						</em>
						»
					</p>
				</>
			)}
		</>
	)
}

export default Page7
