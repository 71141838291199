import image from '../img/page17.webp'

const Page17 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				Старик осторожно распечатал письмо и, прочитав его, удивлённо посмотрел
				на Люкса. «Это интересно!» — сказал он. — «Оказывается, это письмо не
				для меня. Оно адресовано тому, кто читает эту историю прямо сейчас!»
			</p>
		</>
	)
}

export default Page17
