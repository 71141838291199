import React, {
	JSXElementConstructor,
	ReactElement,
	Suspense,
	lazy
} from 'react'
import { Layout, ConfigProvider, FloatButton, notification } from 'antd'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { Header1 } from './components/Header/Header'
import { CustomFooter } from './components/Footer/Footer'
import { WorkList } from './components/WorkList/MyTasksContainer'
import { Result404 } from './components/common/Results/404'
import ruRU from 'antd/es/locale/ru_RU'
import { Login } from './components/Login/Login'
import { Loading } from './components/common/Loading'
import { useSelector } from 'react-redux'
import { getUserDataAccessSelector } from './redux/Selectors/authSelectors'
import { FinishedTasksNotification } from './components/Notifications/FinishedTasksNotification'
import { BadMonthPlanNotification } from './components/Notifications/BadMonthPlanNotification'
import ChristmasStory from './components/Christmas/ChristmasStory'
import {
	getSecretSantaSettingsSelector,
	getSnowSettingsSelector
} from './redux/Selectors/appSelectors'
import SnowEffect from './components/Christmas/SnowEffect'

const Prints = lazy(() =>
	import('./components/Prints/Prints').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Equipment = lazy(() =>
	import('./components/ObjectsList/Equipment').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const EquipCard = lazy(() =>
	import('./components/ObjectsList/EquipCard/EquipCard').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Premises = lazy(() =>
	import('./components/ObjectsList/Premises').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const PremCard = lazy(() =>
	import('./components/ObjectsList/PremCard/PremCard').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Monitoring = lazy(() =>
	import('./components/WorkList/AllTasksContainer').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Systems = lazy(() =>
	import('./components/ObjectsList/Systems').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const SysCard = lazy(() =>
	import('./components/ObjectsList/SysCard/SysCard').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Processes = lazy(() =>
	import('./components/ObjectsList/Processes').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const ProcCard = lazy(() =>
	import('./components/ObjectsList/ProcCard/ProcCard').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const PaperPlanes = lazy(() =>
	import('./components/Paperplanes/PaperPlanes').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Vacations = lazy(() =>
	import('./components/Vacations/Vacations').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Settings = lazy(() =>
	import('./components/Settings/Settings').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Painter = lazy(() =>
	import('./components/Painter/Painter').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Monplans = lazy(() =>
	import('./components/Monplans/Monplans').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Reports = lazy(() =>
	import('./components/Monplans/Reports').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const VmpPlans = lazy(() =>
	import('./components/VmpPlans/VmpPlans').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Instruments = lazy(() =>
	import('./components/Instruments/Instruments').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const InstCard = lazy(() =>
	import('./components/Instruments/InstCard/InstCard').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)
const Signal = lazy(() =>
	import('./components/Signal/Signal').then(
		module =>
			({ default: module.default } as { default: React.ComponentType<any> })
	)
)

type AppPropsType = {
	handleThemeChange: (checked: boolean) => void
	themeType: string
	theme: any
	contextHolder: ReactElement<any, string | JSXElementConstructor<any>>
}

export const App: React.FC<AppPropsType> = ({
	handleThemeChange,
	themeType,
	theme,
	contextHolder
}) => {
	const userAccess = useSelector(getUserDataAccessSelector)
	const secretSantaSettings = useSelector(getSecretSantaSettingsSelector)
	const snowSettings = useSelector(getSnowSettingsSelector)
	const [api, notificationsHolder] = notification.useNotification()

	const customLightTheme = {
		token: {
			colorPrimary: '#1DA57A', // Основной зеленый цвет
			colorText: '#07430b', // Белый цвет текста
			borderRadius: 4, // Радиус границ
			colorLink: '#106b15', // Цвет ссылок
			colorWarning: '#ff9900',
			colorError: '#ff0000'
		},
		components: {
			Tabs: {
				itemHoverColor: 'white',
				cardBg: 'rgba(5,122,54,0.3)',
				cardHeight: 35,
				itemSelectedColor: 'rgba(5,122,54,1)'
			},
			Layout: {
				headerBg: 'white'
			}
		}
	}

	const customDarkTheme = {
		token: {
			colorWarning: '#de9904',
			colorError: '#c81717'
		},
		components: {
			Tabs: {
				itemHoverColor: 'white',
				cardHeight: 35
			},
			Layout: {
				headerBg: '#141414'
			}
		}
	}

	return (
		<>
			{contextHolder}
			{notificationsHolder}
			{userAccess === '1' && <FinishedTasksNotification api={api} />}
			{userAccess === '1' && <BadMonthPlanNotification api={api} />}
			<ConfigProvider
				theme={
					themeType === 'dark'
						? { algorithm: theme.darkAlgorithm, ...customDarkTheme }
						: { algorithm: theme.defaultAlgorithm, ...customLightTheme }
				}
				locale={ruRU}
			>
				<Layout style={{ minHeight: '100vh' }}>
					<Header1 swithTheme={handleThemeChange} typeTheme={themeType} />
					<Layout>
						<Routes>
							<Route path='/login' element={<Login />} />
							<Route
								path='/equipment'
								element={
									<Suspense fallback={<Loading />}>
										<Equipment />
									</Suspense>
								}
							/>
							<Route
								path='/equipment/:id'
								element={
									<Suspense fallback={<Loading />}>
										<EquipCard />
									</Suspense>
								}
							/>
							<Route
								path='/premises'
								element={
									<Suspense fallback={<Loading />}>
										<Premises />
									</Suspense>
								}
							/>
							<Route
								path='/premises/:id'
								element={
									<Suspense fallback={<Loading />}>
										<PremCard />
									</Suspense>
								}
							/>
							<Route
								path='/systems'
								element={
									<Suspense fallback={<Loading />}>
										<Systems />
									</Suspense>
								}
							/>
							<Route
								path='/systems/:id'
								element={
									<Suspense fallback={<Loading />}>
										<SysCard />
									</Suspense>
								}
							/>
							<Route
								path='/processes'
								element={
									<Suspense fallback={<Loading />}>
										<Processes />
									</Suspense>
								}
							/>
							<Route
								path='/processes/:id'
								element={
									<Suspense fallback={<Loading />}>
										<ProcCard />
									</Suspense>
								}
							/>
							<Route path='/work' element={<WorkList />} />
							<Route
								path='/monitoring'
								element={
									<Suspense fallback={<Loading />}>
										<Monitoring />
									</Suspense>
								}
							/>
							<Route
								path='/instruments'
								element={
									<Suspense fallback={<Loading />}>
										<Instruments />
									</Suspense>
								}
							/>
							<Route
								path='/instruments/:id'
								element={
									<Suspense fallback={<Loading />}>
										<InstCard />
									</Suspense>
								}
							/>
							<Route
								path='/signal'
								element={
									<Suspense fallback={<Loading />}>
										<Signal />
									</Suspense>
								}
							/>
							<Route
								path='/monplans'
								element={
									<Suspense fallback={<Loading />}>
										<Monplans />
									</Suspense>
								}
							/>
							<Route
								path='/monplans/:year/:month'
								element={
									<Suspense fallback={<Loading />}>
										<Monplans />
									</Suspense>
								}
							/>
							<Route
								path='/reports'
								element={
									<Suspense fallback={<Loading />}>
										<Reports />
									</Suspense>
								}
							/>
							<Route
								path='/reports/:year/:month'
								element={
									<Suspense fallback={<Loading />}>
										<Reports />
									</Suspense>
								}
							/>
							<Route
								path='/vmp'
								element={
									<Suspense fallback={<Loading />}>
										<VmpPlans />
									</Suspense>
								}
							/>
							<Route
								path='/vmp/:number/:year'
								element={
									<Suspense fallback={<Loading />}>
										<VmpPlans />
									</Suspense>
								}
							/>
							<Route
								path='/vacations'
								element={
									<Suspense fallback={<Loading />}>
										<Vacations />
									</Suspense>
								}
							/>
							<Route
								path='/paperplanes'
								element={
									<Suspense fallback={<Loading />}>
										<PaperPlanes />
									</Suspense>
								}
							/>
							<Route
								path='/painter'
								element={
									<Suspense fallback={<Loading />}>
										<Painter />
									</Suspense>
								}
							/>
							<Route
								path='/settings'
								element={
									<Suspense fallback={<Loading />}>
										<Settings />
									</Suspense>
								}
							/>
							<Route
								path='/prints'
								element={
									<Suspense fallback={<Loading />}>
										<Prints />
									</Suspense>
								}
							/>
							<Route
								path='/prints/:report/:page?'
								element={
									<Suspense fallback={<Loading />}>
										<Prints />
									</Suspense>
								}
							/>
							<Route
								path='/changeList/:number/:year'
								element={
									<Suspense fallback={<Loading />}>
										<VmpPlans />
									</Suspense>
								}
							/>
							<Route path='*' element={<Result404 />} />
						</Routes>
						<FloatButton.BackTop />
					</Layout>
					<CustomFooter />
				</Layout>
			</ConfigProvider>
			{userAccess ? secretSantaSettings === '1' && <ChristmasStory /> : null}
			{userAccess ? snowSettings === '1' && <SnowEffect /> : null}
		</>
	)
}
