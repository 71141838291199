import image from '../img/page16.webp'

const Page16 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				Люкс шагнул вперёд и постучал в дверь. На пороге появился улыбающийся
				старик с добрыми глазами.
				<p style={{ margin: '3px 0px 20px 0px', textIndent: '20px' }}>
					<em>Ты пришёл!</em> — воскликнул он, заметив письмо в руках Люкса.
					<em> «Ты знаешь, как я тебя ждал!</em>». Люкс с улыбкой протянул
					письмо и сказал: «
					<em>
						Это письмо для тебя, от самого Санты. Он велел мне доставить его
						тебе прямо в руки
					</em>
					».
				</p>
			</p>
		</>
	)
}

export default Page16
