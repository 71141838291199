import image from '../img/page13.webp'

const Page13 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				- <em>Молодец!</em> — сказала ёлка, её ветви засверкали, и на них тут же
				появились разноцветные огоньки. -{' '}
				<em>Теперь ты можешь повесить на меня шары!</em>
			</p>
			<p>Люкс с улыбкой принялся украшать ёлку.</p>
		</>
	)
}

export default Page13
