import { Input } from 'antd'
import image from '../img/page5.webp'

const Page19 = ({ setSecondAnswer }) => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				Люкс не смог отгадать загадку, но Снеговик подбодрил его - «Правильный
				ответ был - ветер, но не переживай, попробуй отгадать вторую загадку:
				что всегда с тобой, но ты никогда не видишь это целиком?»
			</p>
			<Input
				placeholder='Введите свой ответ'
				onChange={e => setSecondAnswer(e.currentTarget.value)}
				style={{ marginBottom: '20px' }}
			/>
		</>
	)
}

export default Page19
