import image0 from '../img/page0.webp'
import image1 from '../img/page1.webp'
import image2 from '../img/page2.webp'
import image3 from '../img/page3.webp'
import image4 from '../img/page4.webp'
import image5 from '../img/page5.webp'
import image6 from '../img/page6.webp'
import image7 from '../img/page7.webp'
import image8 from '../img/page8.png'
import image9 from '../img/page9.webp'
import image10 from '../img/page10.webp'
import image11 from '../img/page11.png'
// import image12 from '../img/page12.webp'
import image13 from '../img/page13.webp'
import image15 from '../img/page15.webp'
import image16 from '../img/page16.webp'
import image17 from '../img/page17.webp'
import image20 from '../img/page20.webp'
import tree from '../img/tree.webp'
import letter from '../img/letter.png'
import santa from '../img/santa.png'
import ball1 from '../img/goldBall.png'
import ball2 from '../img/silverBall.png'
import ball3 from '../img/redBall.png'

const PreloadImages = () => {
	return (
		<div className='christmas-preload-image'>
			<div style={{ backgroundImage: `url(${image0})` }} />
			<div style={{ backgroundImage: `url(${image1})` }} />
			<div style={{ backgroundImage: `url(${image2})` }} />
			<div style={{ backgroundImage: `url(${image3})` }} />
			<div style={{ backgroundImage: `url(${image4})` }} />
			<div style={{ backgroundImage: `url(${image5})` }} />
			<div style={{ backgroundImage: `url(${image6})` }} />
			<div style={{ backgroundImage: `url(${image7})` }} />
			<div style={{ backgroundImage: `url(${image8})` }} />
			<div style={{ backgroundImage: `url(${image9})` }} />
			<div style={{ backgroundImage: `url(${image10})` }} />
			<div style={{ backgroundImage: `url(${image11})` }} />
			<div style={{ backgroundImage: `url(${image13})` }} />
			<div style={{ backgroundImage: `url(${image15})` }} />
			<div style={{ backgroundImage: `url(${image16})` }} />
			<div style={{ backgroundImage: `url(${image17})` }} />
			<div style={{ backgroundImage: `url(${image20})` }} />
			<div style={{ backgroundImage: `url(${tree})` }} />
			<div style={{ backgroundImage: `url(${letter})` }} />
			<div style={{ backgroundImage: `url(${santa})` }} />
			<div style={{ backgroundImage: `url(${ball1})` }} />
			<div style={{ backgroundImage: `url(${ball2})` }} />
			<div style={{ backgroundImage: `url(${ball3})` }} />
		</div>
	)
}

export default PreloadImages
