import { appInstance as inst } from "./instance"

export const paperplanesAPI = {
    getPaperplanes() {
        return inst.get(`getPaperplanes.php`).then(res => res.data)
    },
    deletePaperplanes(id: string) {
        return inst.post(`deletePaperplanes.php`, { id }, {}).then(res => res.data)
    },
    createPaperplanes(files: any) {
        const formData = new FormData()
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append("image[]", file)
            }
        } else {
            formData.append("image[]", files)
        }
        return inst.post(`createPaperplanes.php`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    setPaperplanesDescription(id: string, description: string) {
        return inst.post(`setPaperplanesDescription.php`, { id, description }, {}).then(res => res.data)
    },
}