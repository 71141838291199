import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../redux/store"
import { getBadMonthPlan } from "../../redux/Selectors/appSelectors"
import { useEffect } from "react"
import { getBadMonthPlanTasksCount,  } from "../../redux/Reducers/appReducer"
import { NotificationInstance } from "antd/es/notification/interface"

interface props {
    api: NotificationInstance
}

export const BadMonthPlanNotification: React.FC<props> = ({api}) => {
    const dispatch: AppDispatch = useDispatch()
    const badMonthPlanTasksCount = useSelector(getBadMonthPlan)

    useEffect(() => {
        setTimeout(() => dispatch(getBadMonthPlanTasksCount()), 40)

        const interval = setInterval(() => {
            dispatch(getBadMonthPlanTasksCount());
        }, 18000000) // 18000000 мс = 5 часов

        return () => clearInterval(interval);
    }, [])

    const openNotificationBadMonthPlan = () => {
        api['warning']({
          message: 'Незаполненный план на месяц',
          description: 'В плане на текущий месяц имеются незаполненные задачи (' + badMonthPlanTasksCount + ' шт.)',
          duration: null
        })
      }

      useEffect(() => {
        badMonthPlanTasksCount > 0 && openNotificationBadMonthPlan()
     }, [badMonthPlanTasksCount])

    return null
}