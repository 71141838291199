import { useLocation, useNavigate } from 'react-router-dom'
import { AppDispatch } from './redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {
	getAuthUserNameSelector,
	getIsAuthSelector
} from './redux/Selectors/authSelectors'
import { loginOfCookieData } from './redux/Reducers/authReducer'
import {
	getAppErrorMessage,
	getAppLoadingMessage,
	getAppSuccessMessage,
	getInitializeAppStatus,
	getSecretSantaSettingsSelector,
	getSnowSettingsSelector,
	getTermSettingsSelector,
	getThemeType,
	getWhatAllWantsSelector
} from './redux/Selectors/appSelectors'
import {
	appActions,
	getLabelTermSettings,
	getSecretSantaSettings,
	getSnowSettings,
	santaGetWhatAllWant,
	setTheme
} from './redux/Reducers/appReducer'
import { useEffect } from 'react'
import { App } from './App'
import { message } from 'antd'
import { setCookie } from './components/common/cookie'

export const AppContainer: React.FC = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch: AppDispatch = useDispatch()
	const isAuth = useSelector(getIsAuthSelector)
	const authUserName = useSelector(getAuthUserNameSelector)
	const initializeAppStatus = useSelector(getInitializeAppStatus)
	const themeType = useSelector(getThemeType)
	const labelTerm = useSelector(getTermSettingsSelector)
	const secretSanta = useSelector(getSecretSantaSettingsSelector)
	const snow = useSelector(getSnowSettingsSelector)
	const whatAllWants = useSelector(getWhatAllWantsSelector)

	const { theme } = require('antd/lib')

	const handleThemeChange = (checked: boolean) => {
		if (checked) {
			dispatch(setTheme('light'))
			setCookie('theme', 'light', 30)
		} else {
			dispatch(setTheme('dark'))
			setCookie('theme', 'dark', 30)
		}
	}

	// возможно надо тут прописать сразу все настройки для инициализации
	useEffect(() => {
		if (isAuth) {
			!labelTerm && dispatch(getLabelTermSettings())
			!secretSanta && dispatch(getSecretSantaSettings())
			!snow && dispatch(getSnowSettings())
			whatAllWants.length === 0 && dispatch(santaGetWhatAllWant())
		}
	}, [isAuth])

	const currentDay = new Date().getDate()
	const currentMonth = new Date().getMonth()

	const santaMonth = 10 // Ноябрь
	const santaDay = 26 // 26-е число

	const snowMonth = 10 // Ноябрь
	const snowDay = 18 // 18-е число

	const endMonth = 11 // Декабрь
	const endDay = 10 // 10-е число

	useEffect(() => {
		let getSanta: NodeJS.Timer | null = null
		let getSnow: NodeJS.Timer | null = null

		if (whatAllWants.find(e => e.name === authUserName)?.giftManId === '') {
			// Проверка на диапазон "с 26 ноября по 10 декабря"
			if (
				(currentMonth > santaMonth ||
					(currentMonth === santaMonth && currentDay >= santaDay)) &&
				(currentMonth < endMonth ||
					(currentMonth === endMonth && currentDay <= endDay))
			) {
				if (snow === '1' && secretSanta === '0') {
					getSanta = setInterval(() => {
						dispatch(getSecretSantaSettings())
					}, 60000) // 1 минута
				} else if (snow === '1' && secretSanta === '1') {
					getSanta = setInterval(() => {
						dispatch(getSecretSantaSettings())
					}, 600000) // 10 минут
				}
			}
		}

		// Проверка на диапазон "с 18 ноября по 10 декабря"
		if (
			(currentMonth > snowMonth ||
				(currentMonth === snowMonth && currentDay >= snowDay)) &&
			(currentMonth < endMonth ||
				(currentMonth === endMonth && currentDay <= endDay))
		) {
			if (snow === '0') {
				getSnow = setInterval(() => {
					dispatch(getSnowSettings())
				}, 300000) // 5 минут
			} else if (snow === '1') {
				getSnow = setInterval(() => {
					dispatch(getSnowSettings())
				}, 3600000) // 1 час
			}
		}

		// Очистка всех интервалов при изменении зависимостей
		return () => {
			if (getSanta) clearInterval(getSanta)
			if (getSnow) clearInterval(getSnow)
		}
	}, [dispatch, snow, secretSanta, currentMonth, currentDay, whatAllWants])

	const errorMessage = useSelector(getAppErrorMessage)
	const successMessage = useSelector(getAppSuccessMessage)
	const loadingMessage = useSelector(getAppLoadingMessage)

	const [messageApi, contextHolder] = message.useMessage()

	useEffect(() => {
		if (errorMessage) {
			messageApi.open({
				type: 'error',
				content: errorMessage,
				duration: 7
			})
			dispatch(appActions.setErrorMessage(null))
		} else if (loadingMessage) {
			messageApi.open({
				type: 'loading',
				content: loadingMessage
			})
		} else if (successMessage) {
			messageApi.open({
				type: 'success',
				content: successMessage,
				duration: 7
			})
			dispatch(appActions.setSuccessMessage(null))
		}
	}, [errorMessage, successMessage, loadingMessage])

	useEffect(() => {
		!isAuth && dispatch(loginOfCookieData())
	}, [])

	useEffect(() => {
		if (initializeAppStatus) {
			if (!isAuth) {
				navigate('/login')
			} else {
				location.pathname === '/' && navigate('/work')
				location.pathname === '/login' && navigate('/work')
			}
		}
	}, [isAuth, initializeAppStatus, location.pathname])

	return (
		<App
			contextHolder={contextHolder}
			theme={theme}
			themeType={themeType}
			handleThemeChange={handleThemeChange}
		/>
	)
}
