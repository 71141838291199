import { Input, Typography } from 'antd'

import image from '../img/page0.webp'

const Page0 = ({ whatIWant, setWhatIWant, unreadyUsersCount, IWant }) => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				В одной заснеженной деревне, где всегда царит волшебство, живет
				маленький эльф по имени Люкс. Он помогает Санте готовить подарки для
				ребят со всего мира. Но вот беда — Люкс не знает, какой подарок ты
				мечтаешь получить на Рождество! Прежде чем отправиться в увлекательное
				путешествие вместе с Люксом, напиши в окошке ниже, что ты хочешь
				получить на рождество?
			</p>
			<Input
				style={{ marginBottom: '5px' }}
				onChange={e => setWhatIWant(e.currentTarget.value)}
				maxLength={100}
				disabled={IWant !== ''}
				value={IWant ? IWant : undefined}
				placeholder='например - бутылочка хорошего виски'
			/>
			{IWant === '' && (
				<div
					style={{
						fontSize: '12px',
						color: 'gray',
						textAlign: 'right',
						marginBottom: '25px',
						padding: '0'
					}}
				>
					{whatIWant.length}/100 символов
				</div>
			)}
			{IWant !== '' && unreadyUsersCount > 0 && (
				<Typography.Text type='warning'>
					Теперь подождем остальных (осталось {unreadyUsersCount} человек)
				</Typography.Text>
			)}
			{(IWant !== '' && unreadyUsersCount) === 0 && (
				<Typography.Text type='success'>
					Все готовы, давай начнем приключение!
				</Typography.Text>
			)}
		</>
	)
}

export default Page0
