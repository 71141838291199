import { AppStateType } from '../store'

export const getInitializeAppStatus = (state: AppStateType) => {
	return state.app.isInitialized
}

export const getEquipGroupsSelector = (state: AppStateType) => {
	return state.app.equipGroups
}

export const getPremModesSelector = (state: AppStateType) => {
	return state.app.premModes
}

export const getDepartmentsSelector = (state: AppStateType) => {
	return state.app.departments
}

export const getVMPDepartmentsSelector = (state: AppStateType) => {
	return state.app.vmpDepartments
}

export const getIntervals = (state: AppStateType) => {
	return state.app.intervals
}

export const getSopCodeFormSelector = (state: AppStateType) => {
	return state.app.sopCodeForm
}

export const getAllValidatorsSelector = (state: AppStateType) => {
	return state.app.allValidators
}

export const getAllUsersSelector = (state: AppStateType) => {
	return state.app.allUsers
}

export const getVacationsDataSelector = (state: AppStateType) => {
	return state.app.vacationsData
}

export const vacationsIsLoadingSelector = (state: AppStateType) => {
	return state.app.vacationsIsLoading
}

export const usersIsLoadingSelector = (state: AppStateType) => {
	return state.app.usersIsLoading
}

export const getThemeType = (state: AppStateType) => {
	return state.app.themeType
}

export const getPainterDataSelector = (state: AppStateType) => {
	return state.app.painterData
}

export const getCodeSettingsSelector = (state: AppStateType) => {
	return state.app.codeSettingsData
}

export const getIntervalsByAr = (
	state: AppStateType,
	ar: string | undefined = '0'
) => {
	const allIntervalsData = getIntervals(state) // Используем существующий селектор для получения всего массива данных

	// Ищем объект с заданным id в массиве
	const intervalWithAr = allIntervalsData.find(e => e.value === ar)

	return intervalWithAr?.interval // Возвращаем объект или null, если не найден
}

export const getPremModesIsLoadingSelector = (state: AppStateType) => {
	return state.app.premModesIsLoading
}

export const getEquipGroupsIsLoadingSelector = (state: AppStateType) => {
	return state.app.equipGroupsIsLoading
}

export const getCodeFormsIsLoadingSelector = (state: AppStateType) => {
	return state.app.codeFormsIsLoading
}

export const getDepartmentsIsLoadingSelector = (state: AppStateType) => {
	return state.app.departmentsIsLoading
}

export const getVMPDepartmentsIsLoadingSelector = (state: AppStateType) => {
	return state.app.VMPDepartmentsIsLoading
}

export const getAddToMonthPlanIsLoading = (state: AppStateType) => {
	return state.app.addToMonthPlanIsLoading
}

export const getMonthPlanObjectDataSelector = (state: AppStateType) => {
	return state.app.monthPlanObjectData
}

export const getUserActionsSelector = (state: AppStateType) => {
	return state.app.userActions
}

export const getUserAccountsActionsSelector = (state: AppStateType) => {
	return state.app.userAccountsActions
}

export const getAppErrorMessage = (state: AppStateType) => {
	return state.app.errorMessage
}

export const getAppSuccessMessage = (state: AppStateType) => {
	return state.app.successMessage
}

export const getAppLoadingMessage = (state: AppStateType) => {
	return state.app.loadingMessage
}

export const getTermSettingsSelector = (state: AppStateType) => {
	return state.app.termSettings
}

export const getSecretSantaSettingsSelector = (state: AppStateType) => {
	return state.app.secretSantaSettings
}

export const getSnowSettingsSelector = (state: AppStateType) => {
	return state.app.snowSettings
}

export const getServerSelector = (state: AppStateType) => {
	return state.app.server
}

export const getMonthPlanDataForBlockAddReestrDataSelector = (
	state: AppStateType
) => {
	return state.app.monthPlanDataForBlockAddReestrData
}

export const getCompanyName = (state: AppStateType) => {
	return state.app.companyName
}

export const getReestrDataForReportSelector = (state: AppStateType) => {
	return state.app.reestrDataForReport
}

export const getFinishedTasks = (state: AppStateType) => state.app.finishedTasks
export const getBadMonthPlan = (state: AppStateType) => state.app.badMonthPlan
export const getWhatAllWantsSelector = (state: AppStateType) =>
	state.app.whatAllWants
