import { useSelector } from 'react-redux'
import image from '../img/letter.png'
import santa from '../img/santa.png'
import { getAuthUserNameSelector } from '../../../redux/Selectors/authSelectors'

const Page18 = ({ users, whatAllWants }) => {
	const myFio = useSelector(getAuthUserNameSelector)
	const myId = users.find(e => e.fio === myFio).id // число
	const yourGiftManId = whatAllWants.find(
		e => e.userId === myId.toString()
	).giftManId //строка
	const url = users.find(e => e.id.toString() === yourGiftManId)?.url
	const myUserData = users.find(e => e.id === myId)

	const yourGiftManData = users.find(e => e.id.toString() === yourGiftManId)

	const whatHeWant = whatAllWants.find(
		e => e.userId === yourGiftManId
	)?.whatHeWant

	return yourGiftManData ? (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<div className='letterWrapper'>
				<p className='hello'>Здравствуй, {myUserData.name}!</p>
				<p className='text'>
					У меня к тебе <strong>важная просьба</strong>.
				</p>
				<p className='text'>
					Как ты знаешь, перед Рождеством дел невпроворот, и мне очень нужна
					твоя помощь. Ко мне{' '}
					{yourGiftManData.sex === 'm'
						? 'обратился один замечательный парень'
						: 'обратилась одна замечательная девушка'}{' '}
					по имени {yourGiftManData.name} и{' '}
					{yourGiftManData.sex === 'm' ? 'загадал' : 'загадала'} желание.
				</p>
				<div className='avatarWrapper'>
					<p className='text atAvatar'>
						Если вдруг возникнут сложности, я приложил{' '}
						{yourGiftManData.sex === 'm' ? 'его' : 'её'} фотографию, чтобы тебе
						было проще {yourGiftManData.sex === 'm' ? 'его' : 'её'} найти.
					</p>
					<img className='avatar' src={url} alt='' />
				</div>
				<p className='text' style={{ marginTop: '20px' }}>
					Скажу по секрету, {yourGiftManData.name} уже давно кое-что хочет
					получить на рождество - «{whatHeWant}», если ты вдруг не знаешь, что{' '}
					{yourGiftManData.sex === 'm' ? 'ему' : 'ей'} подарить. Пожалуйста,
					сохрани наш маленький секрет.
				</p>
				<p className='text' style={{ textAlign: 'left', marginTop: '30px' }}>
					<strong>С Рождеством!</strong>
				</p>

				<img
					src={santa}
					alt=''
					style={{
						width: '40%',
						position: 'absolute',
						top: '83%',
						left: '43%'
					}}
				/>
			</div>
		</>
	) : null
}

export default Page18
