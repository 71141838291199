import { Input } from 'antd'
import image from '../img/page5.webp'

const Page5 = ({ setFirstAnswer, firstAnswerWrong }) => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p style={{ margin: '0px' }}>
				Мистер Снеговик поднял свою метлу, словно волшебную палочку, и начал:
			</p>
			<p style={{ margin: '5px 0px 20px 20px' }}>
				<em>
					- Первая загадка: я могу быть тихим, могу быть бурным, но никогда не
					стою на месте. Кто я?
				</em>
			</p>
			<Input
				placeholder='Введите свой ответ'
				onChange={e => setFirstAnswer(e.currentTarget.value)}
				style={{ marginBottom: '20px' }}
			/>
			{firstAnswerWrong && (
				<p>«Неправильно, попробуй еще раз!» — ответил Снеговик</p>
			)}
		</>
	)
}

export default Page5
