import image from '../img/page4.webp'

const Page4 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				Первым на своем пути он встретил Мистера Снеговика. Он стоял посреди
				лесной поляны и держал в руках большую метлу. Когда Люкс подошёл ближе,
				Снеговик покачал головой и сказал:
				<p style={{ margin: '0px 0px 0px 20px' }}>
					-{' '}
					<em>
						Эй, маленький эльф, если ты хочешь пройти дальше, тебе придётся
						разгадать мои загадки, иначе метель закроет тебе дорогу!
					</em>
				</p>
				<p style={{ margin: '0px' }}>
					Люкс нисколько не расстроился — он всегда любил загадки.
				</p>
			</p>
		</>
	)
}

export default Page4
