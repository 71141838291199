import { Input } from 'antd'
import image from '../img/page6.webp'

const Page6 = ({ setSecondAnswer }) => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>Люкс задумался, но затем улыбнулся: «Это ветер!»</p>
			<p>
				<em>«Правильно!»</em> — кивнул Снеговик.{' '}
				<p>
					<em>
						«Теперь вторая загадка: что всегда с тобой, но ты никогда не видишь
						это целиком?»
					</em>
				</p>
			</p>
			<Input
				placeholder='Введите свой ответ'
				onChange={e => setSecondAnswer(e.currentTarget.value)}
				style={{ marginBottom: '20px' }}
			/>
		</>
	)
}

export default Page6
