import image from '../img/page8.png'

const Page8 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				С этими словами Снеговик взмахнул метлой, и тропинка перед Люксом
				очистилась от снега. Эльф поблагодарил его и побежал дальше, предвкушая,
				что ждёт его на пути к ёлке.
			</p>
		</>
	)
}

export default Page8
