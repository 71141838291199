import { Typography } from "antd"
import { ReactNode } from "react"

const { Text } = Typography

type editType = {
    icon?: ReactNode,
    tooltip?: ReactNode,
    editing?: boolean,
    maxLength?: number,
    autoSize?: boolean | { minRows: number, maxRows: number },
    text?: string,
    onChange?: (text: string) => void,
    onCancel?: () => any,
    onStart?: () => any ,
    onEnd?: () => any,
    triggerType?: ('icon' | 'text')[],
    enterIcon?: ReactNode,
    tabIndex?: number,
  }

interface TextProps {
    text: string
    fs?: string
    color?: string
    tp?: 'dan' | 'sec' | 'suc' | 'war' | 'und'
    str?: '1' | undefined
    edit?: editType | boolean | undefined
    ml?: string
}

export const CustomText: React.FC<TextProps> = ({ text, fs, color, tp, str, edit, ml}) => {
    let textType = undefined as 'danger' | 'secondary' | 'success' | 'warning' | undefined

    switch (tp) {
        case 'dan': textType = 'danger'; break
        case 'sec': textType = 'secondary'; break
        case 'suc': textType = 'success'; break
        case 'war': textType = 'warning'; break
        default: textType = undefined; break
    }

    return <Text editable={edit} strong={str ? true : false} type={textType} style={{ fontSize: fs ? fs + 'pt' : undefined, marginLeft: ml ? ml + 'px' : undefined, color: color || undefined}}>{text}</Text>
}