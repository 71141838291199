import image from '../img/page3.webp'

const Page3 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				После всех забавных случаев Санта решил дать Люксу простое, но важное
				задание — доставить письмо одному из своих друзей. Люкс сразу взял свою
				сумку и отправился в путешествие, даже не подозревая, что его ждут самые
				настоящие приключения!
			</p>
		</>
	)
}

export default Page3
