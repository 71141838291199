import image from '../img/page10.webp'

const Page10 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				- Люкс удивлённо остановился и воскликнул: «<em>Какое задание?</em>»
				Ёлка тихо засмеялась, и её ветви заискрились.
				<p style={{ margin: '5px 0px 5px 20px' }}>
					<em>
						- Ты должен найти и собрать все шишки, которые я потеряла. Только
						тогда ты сможешь украсить меня шарами!
					</em>
				</p>
				<p style={{ margin: '0px' }}>
					Люкс огляделся вокруг и заметил, что в снегу лежит несколько маленьких
					шишек, но их было не так уж и много. Ему нужно было поторопиться,
					потому что метель начала усиливаться и нельзя было терять ни минуты.
				</p>
			</p>
		</>
	)
}

export default Page10
