import image from '../img/page1.webp'

const Page1 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				В одной далёкой снежной деревне жил маленький эльф по имени Люкс. Он был
				маленьким весёлым эльфом с зелёной шапочкой и очень любил помогать
				Санте, но иногда всё делал так торопливо, что попадал в забавные
				истории.
			</p>
		</>
	)
}

export default Page1
