import { Footer } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import { getCompanyName } from "../../redux/Selectors/appSelectors";

export const CustomFooter: React.FC = () => {
    const companyName = useSelector(getCompanyName)
    const currentYear = new Date().getFullYear()

    let isCompanyPskPharma = false

    companyName === 'pskPharma' && (isCompanyPskPharma = true)

    return (
        <Footer style={{ position: 'fixed', bottom: '0px', width: '100%', zIndex: '2', height: '30px', padding: '5px 0px 5px 40px' }} >
            ValidControl - современная система управления валидацией.
            {isCompanyPskPharma && ' Разработано специально для ООО «ПСК Фарма».'
            }
            {' Все права защищены © ' + currentYear + ' г.'}
        </Footer>
    )
}