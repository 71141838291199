import { NewProcObjectType } from "../redux/Reducers/processesReducer"
import { procInstance as inst } from "./instance"

export const processesAPI = {
    getProcesses() {
        return inst.get(`getProcList.php`).then(res => res.data)
    },
    getReestrData(id: string) {
        return inst.get(`getReestrData.php?id=${id}&proc`).then(res => res.data)
    },
    uploadMainPhoto(id: string, file: any) {
        let formData = new FormData()
        formData.append("image", file)
        return inst.post(`uploadMainPhoto.php?id=${id}&table=processes`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deleteMainPhoto(id: string) {
        return inst.get(`deleteMainPhoto.php?id=${id}&table=processes`).then(res => res.data)
    },
    updateDescription(id: string,
        nomer?: string,
        serial?: string,
        inv?: string,
        manufacturer?: string,
        manufacturDate?: string,
        name?: string,
        group?: string,
        department?: string,
        VMPDepartment?: string,
        interval?: string
    ) {
        let data = new FormData()
        data.append("id", id)
        data.append("table", 'processes')
        nomer !== undefined && data.append("nomer", nomer)
        serial !== undefined && data.append("serial", serial)
        inv !== undefined && data.append("inv", inv)
        name !== undefined && data.append("name", name)
        manufacturer !== undefined && data.append("manufacturer", manufacturer)
        manufacturDate !== undefined && data.append("manufacturdate", manufacturDate)
        group !== undefined && data.append("group", group)
        department !== undefined && data.append("department", department)
        VMPDepartment !== undefined && data.append("VMPDepartment", VMPDepartment)
        interval !== undefined && data.append("interval", interval)

        return inst.post(`updateDescription.php`, data, {}).then(res => res.data)
    },
    getTechnicalInfo(id: string) {
        return inst.get(`getTechnicalInfo.php?id=${id}`).then(res => res.data)
    },
    updateTechnicalInfo(id: string, text: string) {
        let data = new FormData()
        data.append("id", id)
        data.append("text", text)
        return inst.post(`updateTechnicalInfo.php`, data).then(res => res.data)
    },
    getPhotos(id: string) {
        let data = new FormData()
        data.append("id", id)
        return inst.post(`getPhotos.php`, data).then(res => res.data)
    },
    uploadPhotos(id: string, files: any) {
        let formData = new FormData()
        formData.append("id", id)
        formData.append("table", "processes")
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append("image[]", file)
            }
        } else {
            formData.append("image[]", files)
        }
        return inst.post(`uploadPhotos.php`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deletePhoto(id: string, photoId: string) {
        let formData = new FormData()
        formData.append("id", id)
        formData.append("photoId", photoId)
        return inst.post(`deletePhoto.php`, formData, {}).then(res => res.data)
    },
    updatePdfDescription(photoId: string, text: string, id: string) {
        let data = new FormData()
        data.append("photoId", photoId)
        data.append("text", text)
        data.append("id", id)
        return inst.post(`updatePdfDescription.php`, data, {}).then(res => res.data)
    },
    updateReestrDate(id: string, procId: string, date: string, dateType: 'dvo' | 'dvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("procId", procId)
        data.append("date", date)
        data.append("dateType", dateType)
        return inst.post(`updateReestrDate.php`, data, {}).then(res => res.data)
    },
    updateReestrDateTask(id: string, procId: string, date: string, dateType: 'dvo' | 'dvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("procId", procId)
        data.append("date", date)
        data.append("dateType", dateType)
        return inst.post(`updateReestrDateTask.php`, data, {}).then(res => res.data)
    },
    updateReestrDocsCode(id: string, recordId: string, text: string, dataType: 'nvo' | 'nvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("recordId", recordId)
        data.append("text", text)
        data.append("dataType", dataType)
        return inst.post(`updateReestrDocsCode.php`, data, {}).then(res => {
            return res.data
        })
    },
    updateReestrDocsCodeTask(id: string, recordId: string, text: string, dataType: 'nvo' | 'nvp') {
        let data = new FormData()
        data.append("id", id)
        data.append("recordId", recordId)
        data.append("text", text)
        data.append("dataType", dataType)
        return inst.post(`updateReestrDocsCodeTask.php`, data, {}).then(res => res.data)
    },
    uploadDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', file: any) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("file", file)
        return inst.post(`uploadDocument.php`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
            return res.data
        })
    },
    uploadTaskDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', file: any) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("file", file)
        return inst.post(`uploadTaskDocument.php`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    deleteDocument(objectId: string, recordId: string, dataType: 'vo' | 'vp' | 'pam', url: string) {
        let data = new FormData()
        data.append("objectId", objectId)
        data.append("recordId", recordId)
        data.append("dataType", dataType)
        data.append("url", url)
        return inst.post(`deleteDocument.php`, data, {}).then(res => res.data)
    },
    getCurrentProcData(myProcDataIdArray: Array<string>) {
        return inst.post(`getCurrentProcData.php`, {myProcDataIdArray}, {}).then(res => {
            return res.data
        })
    },
    updateProcWorkData(recordId: string, changeParam: 'et' | 'season' | 'pam2' | 'isCardUpdated', text: string) { // по идее можно убрать
        return inst.post(`updateProcWorkData.php`, { recordId, changeParam, text }, {}).then(res => res.data)
    },
    createNewObject(data: NewProcObjectType) {
        return inst.post(`createNewObject.php`, data, {}).then(res => res.data)
    },
}