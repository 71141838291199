import image from '../img/page20.webp'

const Page20 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				Старик улыбнулся и, похлопав Люкса по плечу, сказал: «Ну что ж, пусть
				рождественская магия творит своё дело!» Они уселись перед тёплым
				камином, наслаждаясь уютом и потрескиванием огня, а письмо лежало на
				столе, готовое исполнить ещё одно рождественское чудо. Люкс счастливо
				вздохнул: «Как здорово быть частью чего-то особенного!»
			</p>
		</>
	)
}

export default Page20
