import { appInstance as inst } from './instance'

export const appAPI = {
	getEquipGroups(type: 'active' | 'all') {
		return inst.get(`getEquipGroups.php?type=${type}`).then(res => res.data)
	},
	getPremModes() {
		return inst.get(`getPremModes.php`).then(res => res.data)
	},
	getDepartments() {
		return inst.get(`getDepartments.php`).then(res => res.data)
	},
	getVMPDepartments() {
		return inst.get(`getVMPDepartments.php`).then(res => res.data)
	},
	getSopCodeForm() {
		return inst.get(`getSopCodeForm.php`).then(res => res.data)
	},
	getAllValidators() {
		return inst.get(`getAllValidators.php`).then(res => res.data)
	},
	getVacationsData() {
		return inst.get(`getVacationsData.php`).then(res => res.data)
	},
	setVacationsData(fio: string, dates: string, month: string) {
		return inst
			.post(`setVacationsData.php`, { fio, dates, month }, {})
			.then(res => res.data)
	},
	deleteVacationsData(fio: string, month: string) {
		return inst
			.post(`deleteVacationsData.php`, { fio, month }, {})
			.then(res => res.data)
	},
	getPainterData() {
		return inst.get(`getPainterData.php`).then(res => res.data)
	},
	setPainterData(data: any) {
		return inst.post(`setPainterData.php`, { data }, {}).then(res => res.data)
	},
	getCodeSettings() {
		return inst.get(`getCodeSettings.php`).then(res => res.data)
	},
	setDepartmentsData(
		id: string,
		name2?: string,
		pos?: string,
		fio?: string,
		stat?: string
	) {
		const requestData =
			name2 !== undefined && name2 !== null
				? { id, name2 }
				: pos !== undefined && pos !== null
				? { id, pos }
				: fio !== undefined && fio !== null
				? { id, fio }
				: { id, stat }
		return inst
			.post(`setDepartmentsData.php`, requestData, {})
			.then(res => res.data)
	},
	setVMPDepartmentsData(
		id: string,
		vmpname1?: string,
		vmpname2?: string,
		code?: string,
		code2?: string,
		isactive?: string,
		menuname?: string
	) {
		const requestData =
			vmpname1 !== undefined && vmpname1 !== null
				? { id, vmpname1 }
				: vmpname2 !== undefined && vmpname2 !== null
				? { id, vmpname2 }
				: code !== undefined && code !== null
				? { id, code }
				: code2 !== undefined && code2 !== null
				? { id, code2 }
				: menuname !== undefined && menuname !== null
				? { id, menuname }
				: { id, isactive }
		return inst
			.post(`setVMPDepartmentsData.php`, requestData, {})
			.then(res => res.data)
	},
	setCodeFormsData(id: string, codeform: string) {
		return inst
			.post(`setCodeSettings.php`, { id, codeform }, {})
			.then(res => res.data)
	},
	setPremModesData(
		id: string,
		type?: string,
		low?: string,
		hight?: string,
		isactive?: string
	) {
		const requestData =
			type !== undefined && type !== null
				? { id, type }
				: low !== undefined && low !== null
				? { id, low }
				: hight !== undefined && hight !== null
				? { id, hight }
				: { id, isactive }
		return inst.post(`setPremModes.php`, requestData, {}).then(res => res.data)
	},
	setEquipGroupsData(id: string, name?: string, isactive?: string) {
		const requestData =
			name !== undefined && name !== null ? { id, name } : { id, isactive }
		return inst
			.post(`setEquipGroups.php`, requestData, {})
			.then(res => res.data)
	},
	createNewDepartment(
		name: string,
		name2: string,
		pos: string,
		fio: string,
		stat: string
	) {
		return inst
			.post(`createNewDepartment.php`, { name, name2, pos, fio, stat }, {})
			.then(res => res.data)
	},
	createNewVMPDepartment(
		vmpname1: string,
		vmpname2: string,
		menuname: string,
		code: string,
		code2: string,
		datevmp: string,
		isactive: string
	) {
		return inst
			.post(
				`createNewVMPDepartment.php`,
				{ vmpname1, vmpname2, menuname, code, code2, datevmp, isactive },
				{}
			)
			.then(res => res.data)
	},
	createNewEquipGroup(name: string, isactive: string) {
		return inst
			.post(`createNewEquipGroup.php`, { name, isactive }, {})
			.then(res => res.data)
	},
	createNewPremMode(
		type: string,
		low: string,
		hight: string,
		isactive: string
	) {
		return inst
			.post(`createNewPremMode.php`, { type, low, hight, isactive }, {})
			.then(res => res.data)
	},
	setVMPConsumers(id: string, data: string[]) {
		return inst
			.post(`setVMPConsumers.php`, { id, data }, {})
			.then(res => res.data)
	},
	getMonthPlanObjectData(
		id: string,
		objectType: 'equipment' | 'premises' | 'systems' | 'processes',
		month: string
	) {
		return inst
			.post(`getMonthPlanObjectData.php`, { id, objectType, month }, {})
			.then(res => res.data)
	},
	createObjectInMonthPlane(
		id: string,
		objectType: 'equipment' | 'premises' | 'systems' | 'processes',
		month: string
	) {
		return inst
			.post(`createObjectInMonthPlane.php`, { id, objectType, month }, {})
			.then(res => res.data)
	},
	addReestrData(
		id: string,
		objectType: 'equipment' | 'premises' | 'systems' | 'processes',
		nvp: string,
		dvp: string,
		nvo: string,
		dvo: string,
		typeval: string
	) {
		return inst
			.post(
				`addReestrData.php`,
				{ id, objectType, nvp, dvp, nvo, dvo, typeval },
				{}
			)
			.then(res => res.data)
	},
	getUserActions() {
		return inst.get(`getUserActions.php`).then(res => res.data)
	},
	getUserAccountsActions() {
		return inst.get(`getUserAccountsActions.php`).then(res => res.data)
	},
	uploadCodeForm(file: any) {
		let data = new FormData()
		data.append('fileform', file)
		return inst
			.post(`uploadCodeForm.php`, data, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(res => res.data)
	},
	getLabelTermSettings() {
		return inst.get(`getLabelTermSettings.php`).then(res => res.data)
	},
	setLabelTermSettings(param: string) {
		return inst
			.post(`setLabelTermSettings.php`, { param }, {})
			.then(res => res.data)
	},
	getSecretSantaSettings() {
		return inst.get(`getSecretSantaSettings.php`).then(res => res.data)
	},
	setSecretSantaSettings(param: string) {
		return inst
			.post(`setSecretSantaSettings.php`, { param }, {})
			.then(res => res.data)
	},
	getSnowSettings() {
		return inst.get(`getSnowSettings.php`).then(res => res.data)
	},
	setSnowSettings(param: string) {
		return inst.post(`setSnowSettings.php`, { param }, {}).then(res => res.data)
	},
	getAllUsers() {
		return inst.get(`getAllUsers.php`).then(res => res.data)
	},
	getFinishedTasksCount() {
		return inst.get(`getFinishedTasks.php`).then(res => res.data)
	},
	getBadMonthPlanTasksCount() {
		return inst.get(`getBadMonthPlan.php`).then(res => res.data)
	},
	getMonthPlanDataForBlockAddReestrData(id: string, tablename: string) {
		return inst
			.get(
				`getMonthPlanDataForBlockAddReestrData.php?id=${id}&tablename=${tablename}`
			)
			.then(res => res.data)
	},
	getReestrDataForReport(sp: string) {
		return inst.get(`getReestrDataForReport.php?sp=${sp}`).then(res => res.data)
	},
	santaSetWhatIWant(text: string) {
		return inst
			.post(`santaSetWhatIWant.php`, { text }, {})
			.then(res => res.data)
	},
	santaGetWhatAllWant() {
		return inst.get(`santaGetWhatAllWant.php`).then(res => res.data)
	},
	santaGetMyGiftFriend() {
		return inst.get(`santaGetMyGiftFriend.php`).then(res => res.data)
	}
}
