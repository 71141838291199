import { Typography } from 'antd'
import image from '../img/page11.png'
import cone from '../img/cone.png'
import { useState } from 'react'

const Page11 = ({ setConeCount, coneCount }) => {
	const [cone1, setCone1] = useState(true)
	const [cone2, setCone2] = useState(true)
	const [cone3, setCone3] = useState(true)
	const [cone4, setCone4] = useState(true)
	const [cone5, setCone5] = useState(true)
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<img
				src={cone}
				alt=''
				onClick={() => {
					setConeCount(coneCount + 1)
					setCone1(false)
				}}
				style={{
					position: 'absolute',
					top: '74.8%',
					left: '73%',
					display: cone1 ? 'block' : 'none',
					cursor: 'grab',
					transform: 'rotate(145deg)',
					width: '1.2em',
					opacity: '0.6'
				}}
			/>
			<img
				src={cone}
				alt=''
				onClick={() => {
					setConeCount(coneCount + 1)
					setCone2(false)
				}}
				style={{
					position: 'absolute',
					top: '81%',
					left: '83%',
					display: cone2 ? 'block' : 'none',
					cursor: 'grab',
					transform: 'rotate(245deg)',
					width: '1.7em',
					opacity: '0.8'
				}}
			/>
			<img
				src={cone}
				alt=''
				onClick={() => {
					setConeCount(coneCount + 1)
					setCone3(false)
				}}
				style={{
					position: 'absolute',
					top: '54%',
					left: '33%',
					display: cone3 ? 'block' : 'none',
					cursor: 'grab',
					transform: 'rotate(30deg)',
					width: '0.9em',
					opacity: '0.6'
				}}
			/>
			<img
				src={cone}
				alt=''
				onClick={() => {
					setConeCount(coneCount + 1)
					setCone4(false)
				}}
				style={{
					position: 'absolute',
					top: '76%',
					left: '45%',
					display: cone4 ? 'block' : 'none',
					cursor: 'grab',
					transform: 'rotate(115deg)',
					width: '1.3em',
					opacity: '0.8'
				}}
			/>
			<img
				src={cone}
				alt=''
				onClick={() => {
					setConeCount(coneCount + 1)
					setCone5(false)
				}}
				style={{
					position: 'absolute',
					top: '83%',
					left: '63%',
					display: cone5 ? 'block' : 'none',
					cursor: 'grab',
					transform: 'rotate(115deg)',
					width: '1.3em',
					opacity: '0.8'
				}}
			/>
			<span>
				Собрано шишек:
				<Typography.Text type={coneCount !== 5 ? 'warning' : 'success'}>
					&nbsp;{coneCount} из 5
				</Typography.Text>
			</span>
		</>
	)
}

export default Page11
