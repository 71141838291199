import image from '../img/page2.webp'

const Page2 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				Однажды Люкс так спешил украсить ёлку, что повесил игрушки на самого
				Санту вместо веток. Санта рассмеялся, но сказал, что отныне ёлки лучше
				украшать без спешки! В другой раз Люкс хотел помочь упаковать подарки,
				но случайно завернул своего друга мышонка Микки в коробку. Хорошо, что
				мышонок вовремя зашевелился и всех рассмешил!
			</p>
		</>
	)
}

export default Page2
