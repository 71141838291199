import image from '../img/page11.png'

const Page12 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} alt='' />
			<p>
				Скоро Люкс нашёл первую шишку, затем вторую, третью, и каждый раз, когда
				он поднимал шишку с земли, ёлка хихикала и сияла все ярче и ярче. Но вот
				осталась последняя шишка, которая закатилась за большой пень. Люкс
				прыгнул, чтобы достать ее, но тут внезапно сильный порыв ветра подхватил
				его и унес в сторону!{' '}
				<p style={{ margin: '5px 0px 20px 0px', textIndent: '20px' }}>
					- <em>Ой!</em> — воскликнул Люкс, но тут же заметил, что шишка
					оказалась прямо перед ним. Он аккуратно поднял ее и с радостью понес к
					ёлке.
				</p>
			</p>
		</>
	)
}

export default Page12
