import { useEffect, useRef } from 'react'

const SnowEffect = () => {
	const snowInstance = useRef(null) // Сохраняем ссылку на экземпляр Snow

	useEffect(() => {
		// Загружаем Snow.min.js
		const script = document.createElement('script')
		script.src = '/snow/Snow.js'
		script.async = true

		script.onload = () => {
			if (window.Snow) {
				snowInstance.current = new window.Snow() // Создаем экземпляр Snow
			}
		}

		document.body.appendChild(script)

		// Очищаем ресурсы при размонтировании компонента
		return () => {
			if (snowInstance.current) {
				snowInstance.current.destroy() // Останавливаем снег
				snowInstance.current = null
			}
			document.body.removeChild(script)
		}
	}, [])

	return null
}

export default SnowEffect
