import tree from '../img/tree.webp'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Image } from 'antd'

const ItemType = {
	BALL: 'ball'
}

const Page14 = ({ balls, setBalls }) => {
	const moveBall = (id, x, y) => {
		setBalls(prev =>
			prev.map(ball => (ball.id === id ? { ...ball, x, y } : ball))
		)
	}

	const allOnTree = balls.every(ball => ball.x !== null && ball.y !== null)
	return (
		<DndProvider backend={HTML5Backend}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Tree balls={balls} moveBall={moveBall} />
				<div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
					{balls
						.filter(ball => ball.x === null && ball.y === null)
						.map(ball => (
							<Ball key={ball.id} id={ball.id} image={ball.image} />
						))}
				</div>
				<p>
					{allOnTree ? (
						<>
							Когда он закончил, ёлка стала просто ослепительно красивой, и Люкс
							почувствовал, как его сердце наполняется теплотой. Но самое
							главное, ёлка вдруг заговорила:{' '}
							<p style={{ margin: '5px 0px 0px 0px', textIndent: '20px' }}>
								<em>
									- Теперь твой путь открыт. Ты готов идти дальше и найти того,
									кому предстоит вручить письмо!
								</em>
							</p>
						</>
					) : (
						'Скорее укрась ёлку!'
					)}
				</p>
			</div>
		</DndProvider>
	)
}

const Tree = ({ balls, moveBall }) => {
	const [, drop] = useDrop({
		accept: ItemType.BALL,
		drop: (item, monitor) => {
			const offset = monitor.getClientOffset()
			const treeRect = document.getElementById('tree').getBoundingClientRect()
			const x = offset.x - treeRect.left
			const y = offset.y - treeRect.top
			moveBall(item.id, x, y)
		}
	})

	return (
		<div id='tree' ref={drop}>
			<Image
				src={tree}
				style={{
					width: '100%',
					position: 'relative',
					zIndex: '0'
				}}
				preview={false}
			/>
			{balls
				.filter(ball => ball.x !== null && ball.y !== null)
				.map(ball => (
					<DraggableBall
						key={ball.id}
						id={ball.id}
						image={ball.image}
						x={ball.x}
						y={ball.y}
						moveBall={moveBall}
					/>
				))}
		</div>
	)
}

const DraggableBall = ({ id, image, x, y, moveBall }) => {
	const [{ isDragging }, drag] = useDrag({
		type: ItemType.BALL,
		item: { id },
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	})

	return (
		<div
			ref={drag}
			style={{
				width: '38px',
				height: '38px',
				backgroundImage: `url(${image})`,
				backgroundSize: 'cover',
				borderRadius: '50%',
				position: x !== null && y !== null ? 'absolute' : 'static',
				left: x !== null ? `${x}px` : undefined,
				top: y !== null ? `${y}px` : undefined,
				cursor: 'grab',
				opacity: isDragging ? 0.5 : 1,
				transition: 'all 0.2s',
				zIndex: '99'
			}}
		/>
	)
}

const Ball = ({ id, image }) => {
	const [, drag] = useDrag({
		type: ItemType.BALL,
		item: { id }
	})

	return (
		<div
			ref={drag}
			style={{
				width: '30px',
				height: '30px',
				backgroundSize: 'cover',
				backgroundImage: `url(${image})`,
				borderRadius: '50%',
				cursor: 'grab',
				zIndex: '99'
			}}
		/>
	)
}

export default Page14
