import image from '../img/page15.webp'

const Page15 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				Люкс продолжил свой путь, и его путешествие уже близилось к завершению.
				Он был уставший, но полон радости, потому что знал: его цель была почти
				выполнена. Впереди, в снежном лесу, он заметил маленькую поляну, где
				стоял уютный деревянный домик, из трубы которого поднимался лёгкий
				дымок.
			</p>
		</>
	)
}

export default Page15
