import image from '../img/page9.webp'

const Page9 = () => {
	return (
		<>
			<img src={image} style={{ width: '100%' }} />
			<p>
				Люкс бежал через снежные просторы, и скоро перед ним появилась высокая
				ёлка, вся покрытая инеем. Это была настоящая волшебная ёлка, с пушистыми
				ветками, на которых не было ни одной игрушки. Она стояла в центре поляны
				и словно ждала, чтобы её украсили. Но как только Люкс подошёл ближе,
				ёлка ожила! Она слегка зашевелила своими ветвями и проговорила:
				<p style={{ margin: '5px 0px 20px 20px' }}>
					-{' '}
					<em>
						Если ты хочешь пройти дальше, тебе придется украсить меня! Но
						сначала выполни моё задание!
					</em>
				</p>
			</p>
		</>
	)
}

export default Page9
