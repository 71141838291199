import React, { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Button, Col, Form, Image, Input, Menu, MenuProps, message, Modal, Row, Space } from 'antd'
import { Header } from "antd/es/layout/layout"
import logo from './../../img/logo.png'
import { useDispatch, useSelector } from "react-redux"
import { getVMPDepartmentsSelector } from "../../redux/Selectors/appSelectors"
import { getVMPDepartments } from "../../redux/Reducers/appReducer"
import { AppDispatch } from "../../redux/store"
import { getAuthUserNameSelector, getEditPassResponseMessage, getIsAuthSelector, getLoginSelector } from "../../redux/Selectors/authSelectors"
import { editPass, logout } from "../../redux/Reducers/authReducer"
import { LockOutlined, SettingOutlined } from "@ant-design/icons"
import darkIcon from './../../img/dark.png'
import lightIcon from './../../img/light.png'
import { CustomText } from "../lite/CustomText"

type HeaderPropsType = {
    swithTheme: (checked: boolean) => void
    typeTheme: string
}

export const Header1: React.FC<HeaderPropsType> = ({ swithTheme, typeTheme }) => {
    const location = useLocation()
    const dispatch: AppDispatch = useDispatch()
    const VMPDepartments = useSelector(getVMPDepartmentsSelector)
    const isAuth = useSelector(getIsAuthSelector)
    const userName = useSelector(getAuthUserNameSelector)
    const editPassResponseMessage = useSelector(getEditPassResponseMessage)
    const login = useSelector(getLoginSelector)
    const [formPass, editFormPass] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {
        if (editPassResponseMessage) {
            console.log(editPassResponseMessage)
            if (editPassResponseMessage.resultCode === 0) {
                editFormPass(false)
                messageApi.open({
                    type: 'success',
                    content: editPassResponseMessage.message,
                    duration: 5
                })
            } else {
                messageApi.open({
                    type: 'error',
                    content: editPassResponseMessage.message,
                    duration: 5
                })
            }
        }
    }, [editPassResponseMessage])

    useEffect(() => {
        isAuth && VMPDepartments.length === 0 && dispatch(getVMPDepartments())
    }, [isAuth])
    const currentYear = new Date().getFullYear()
    const filteredVMPDepartments = VMPDepartments.filter(e => e.isactive === '0').map(e => ({ key: `/vmp/${e.id}`, label: <Link to={`/vmp/${e.id}/${currentYear}`}>{e.menuname}</Link> }))
    const vmpTables = VMPDepartments.filter(e => e.isactive === '0').map(e => "/vmp/" + e.id)
    const vmpTables2 = VMPDepartments.filter(e => e.isactive === '0').map(e => "/changeList/" + e.id)

    const getPathnameWithoutTrailingSlash = (pathname: string) => {
        if (pathname.includes("/equipment")) {
            return "/equipment"
        } else if (pathname.includes("/premises")) {
            return "/premises"
        } else if (pathname.includes("/systems")) {
            return "/systems"
        } else if (pathname.includes("/processes")) {
            return "/processes"
        } else if (pathname.includes("/instruments")) {
            return "/instruments"
        } else if (pathname.includes("/monplans")) {
            return "/monplans"
        } else if (pathname.includes("/reports")) {
            return "/reports"
        } else if (pathname.includes("/prints")) {
            return "/prints"
        }

        for (const table of vmpTables) {
            if (pathname.includes(table)) {
                return table
            }
        }

        for (const table of vmpTables2) {
            if (pathname.includes(table)) {
                const vmpNumber = table.split('/').pop()
                return "/vmp/" + vmpNumber
            }
        }

        return pathname
    }

    const items: MenuProps['items'] = [
        {
            key: '1', label: 'Задачи', children: [
                { key: '/work', label: <Link to='/work'>Мои задачи</Link> },
                { key: '/monitoring', label: <Link to='/monitoring'>Все задачи</Link> },
            ]
        },
        {
            key: '2', label: 'Объекты', children: [
                { key: '/premises', label: <Link to='/premises'>Помещения</Link> },
                { key: '/equipment', label: <NavLink to='/equipment'>Оборудование</NavLink> },
                { key: '/systems', label: <Link to='/systems'>Системы</Link> },
                { key: '/processes', label: <Link to='/processes'>Процессы</Link> },
            ]
        },
        { key: '/signal', label: <Link to='/signal'>Истечение сроков</Link> },
        { key: '/instruments', label: <Link to='/instruments'>Приборы</Link> },
        {
            key: '3', label: `Графики работ`, children: [
                { key: '/monplans', label: <Link to='/monplans'>План на месяц</Link> },
                { key: '/reports', label: <Link to='/reports'>Отчет за месяц</Link> },
                ...filteredVMPDepartments,
                { key: '/vacations', label: <Link to='/vacations'>График отпусков</Link> }
            ]
        },
        { key: '/paperplanes', label: <Link to='/paperplanes'>Схемы этажей</Link> },
        { key: '/painter', label: <Link to='/painter'>Интерактивная доска</Link> },
        { key: '/prints', label: <Link to='/prints'>Отчеты и аудит</Link> },
    ]

    const handleLogout = () => {
        dispatch(logout())
    }

    const [form] = Form.useForm()

    type EditFormDataType = {
        oldPassword: string
        newPassword: string
        confirmPassword: string
    }

    const finishEditPass = (data: EditFormDataType) => {
        dispatch(editPass(login, data.oldPassword, data.confirmPassword))
    }

    const validatePasswords = (_: any, value: any) => {
        if (!value || form.getFieldValue('newPassword') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Пароли не совпадают!'));
    }

    const passwordRules = [
        {
            required: true,
            message: 'Пожалуйста, введите новый пароль!',
        },
        {
            min: 8,
            message: 'Пароль должен содержать минимум 8 символов!',
        },
        {
            pattern: /[A-Z]/,
            message: 'Пароль должен содержать минимум одну заглавную букву!',
        },
        {
            pattern: /\d/,
            message: 'Пароль должен содержать минимум одну цифру!',
        },
        {
            pattern: /[!@#$%^&*(),.?":{}|<>]/,
            message: 'Пароль должен содержать минимум один спецсимвол!',
        },
        {
            pattern: /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/,
            message: 'Пароль может содержать только латинские буквы, цифры и специальные символы.',
        },
    ]

    const userMenuItems: MenuProps['items'] = [
        {
            key: 'userMenuItems',
            label: <CustomText color="#ff9900" fs="12" text={userName} />,
            children: [
                { key: 'editPass', label: <CustomText text='Сменить пароль' /> },
                { key: 'logout', label: <CustomText text='Выход' /> }
            ]
        }
    ];

    return isAuth ? <>
        {contextHolder}
        <Header style={{ margin: '0px', padding: '0px', height: '45px' }}  >
            <Row>
                <Col style={{ textAlign: 'center' }} xs={2} sm={2} md={2} lg={1} xl={1} xxl={1}>
                    <Link to="/work">
                        <Image src={logo} style={{ width: '34px', height: '34px', display: 'inline', position: 'relative', bottom: '10px' }} preview={false} />
                    </Link>
                </Col>
                <Col xs={13} sm={13} md={15} lg={17} xl={18} xxl={20}>
                    {isAuth &&
                        <Menu
                            mode="horizontal"
                            selectedKeys={[getPathnameWithoutTrailingSlash(location.pathname)]}
                            items={items}
                            style={{ backgroundColor: 'none', lineHeight: '45px' }}
                        />
                    }
                </Col>
                <Col style={{ textAlign: 'right', paddingRight: '10px' }} xs={6} sm={6} md={5} lg={4} xl={3} xxl={2}>
                    <Menu
                        mode="horizontal"
                        items={userMenuItems}
                        style={{ backgroundColor: 'none', lineHeight: '45px' }}
                        selectedKeys={['1']}
                        onClick={(e) => {
                            switch (e.key) {
                                case 'editpass': { return () => editFormPass(true) }
                                case 'logout': { return handleLogout() }
                            }
                            if (e.key === 'editPass') {
                                editFormPass(true);
                            }
                        }}
                    />
                    <Modal
                        centered
                        title="Изменение пароля"
                        open={formPass}
                        onCancel={() => editFormPass(false)}
                        footer={false}
                    >
                        <Form
                            onFinish={(data) => finishEditPass(data)}
                            form={form}
                            autoComplete='off'
                            style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', width: '80%', marginLeft: '10%' }}
                        >
                            <Form.Item
                                name="oldPassword"
                                rules={[{ required: true, message: 'Пожалуйста, введите свой старый пароль!' }]}
                                hasFeedback
                            >
                                <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <CustomText text='Старый пароль' />
                                    <Input prefix={<LockOutlined />} type="password" autoComplete="off" onPaste={(e) => e.preventDefault()} />
                                </Space>
                            </Form.Item>

                            <Form.Item
                                name="newPassword"
                                rules={passwordRules}
                                hasFeedback
                            >
                                <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <CustomText text='Новый пароль' />
                                    <Input prefix={<LockOutlined />} type="password" />
                                </Space>
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                rules={[
                                    { required: true, message: 'Пожалуйста, повторите новый пароль!' },
                                    { validator: validatePasswords },
                                ]}
                                hasFeedback
                            >
                                <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <CustomText text='Подтверждение' />
                                    <Input prefix={<LockOutlined />} type="password" />
                                </Space>
                            </Form.Item>

                            <Form.Item style={{ marginTop: '30px', marginBottom: '0px' }} >
                                <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button type={"primary"} htmlType="submit">Изменить пароль</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', placeItems: 'center' }} xs={3} sm={3} md={2} lg={2} xl={2} xxl={1}>
                    <Button type={typeTheme === 'dark' ? 'link' : 'link'} shape="circle" icon={<NavLink to='settings' style={{ display: 'inline-block' }}><SettingOutlined style={{ fontSize: '16pt', position: 'relative', bottom: '9px' }} /></NavLink>} />
                    <Button style={{ position: 'relative', bottom: '10px' }} shape="circle" onClick={() => swithTheme(typeTheme === 'dark' ? true : false)} type={typeTheme === 'dark' ? 'link' : 'link'} icon={<Image preview={false} src={typeTheme === 'dark' ? lightIcon : darkIcon} />} />
                </Col>
            </Row>
        </Header>
    </> : <></>
}