import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import Page1 from './pages/Page1'
import Page2 from './pages/Page2'
import Page3 from './pages/Page3'
import Page4 from './pages/Page4'
import Page5 from './pages/Page5'
import Page6 from './pages/Page6'
import Page7 from './pages/Page7'
import Page8 from './pages/Page8'
import Page9 from './pages/Page9'
import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons'
import Page10 from './pages/Page10'
import Page11 from './pages/Page11'
import Page12 from './pages/Page12'
import Page13 from './pages/Page13'
import Page14 from './pages/Page14'
import Page15 from './pages/Page15'
import Page16 from './pages/Page16'
import Page17 from './pages/Page17'
import Page18 from './pages/Page18'
import goldBall from './img/goldBall.png'
import redBall from './img/redBall.png'
import silverBall from './img/silverBall.png'
import foto21 from './img/avatars/21.jpg'
import foto22 from './img/avatars/22.jpg'
import foto23 from './img/avatars/23.jpg'
import foto24 from './img/avatars/24.jpg'
import foto41 from './img/avatars/41.jpg'
import foto26 from './img/avatars/26.jpg'
import foto49 from './img/avatars/49.jpg'
import Page0 from './pages/Page0'
import { useDispatch, useSelector } from 'react-redux'
import { getWhatAllWantsSelector } from '../../redux/Selectors/appSelectors'
import { AppDispatch } from '../../redux/store'
import {
	santaGetMyGiftFriend,
	santaGetWhatAllWant,
	santaSetWhatIWant
} from '../../redux/Reducers/appReducer'
import Page19 from './pages/Page19'
import { getAuthUserNameSelector } from '../../redux/Selectors/authSelectors'
import PreloadImages from './pages/PreloadImages'
import Page20 from './pages/Page20'

const initialBalls = [
	{ id: 1, image: redBall, x: null, y: null },
	{ id: 6, image: goldBall, x: null, y: null },
	{ id: 11, image: silverBall, x: null, y: null },
	{ id: 2, image: redBall, x: null, y: null },
	{ id: 12, image: silverBall, x: null, y: null },
	{ id: 5, image: goldBall, x: null, y: null },
	{ id: 3, image: redBall, x: null, y: null },
	{ id: 9, image: silverBall, x: null, y: null },
	{ id: 4, image: redBall, x: null, y: null },
	{ id: 7, image: goldBall, x: null, y: null },
	{ id: 10, image: silverBall, x: null, y: null },
	{ id: 8, image: goldBall, x: null, y: null }
]

const users = [
	{ id: 21, url: foto21, sex: 'm', name: 'Кирилл', fio: 'Чернов К.А.' },
	{ id: 22, url: foto22, sex: 'm', name: 'Арслан', fio: 'Нугуманов А.Н.' },
	{ id: 23, url: foto23, sex: 'm', name: 'Никита', fio: 'Кукушкин Н.А.' },
	{ id: 24, url: foto24, sex: 'm', name: 'Ваня', fio: 'Зелинский И.А.' },
	{ id: 41, url: foto41, sex: 'm', name: 'Сева', fio: 'Юдин В.И.' },
	{ id: 26, url: foto26, sex: 'f', name: 'Элина', fio: 'Дарина Э.Г.' },
	{ id: 49, url: foto49, sex: 'm', name: 'Максим', fio: 'Нагорный М.Н.' }
]

const ChristmasStory = () => {
	const dispatch: AppDispatch = useDispatch()
	const [show, setShow] = useState(true)
	const [currentPage, setCurrentPage] = useState(0)
	const [firstAnswer, setFirstAnswer] = useState('')
	const [firstAnswerWrong, setFirstAnswerWrong] = useState(false)
	const [secondAnswer, setSecondAnswer] = useState('')
	const [coneCount, setConeCount] = useState(0)
	const [balls, setBalls] = useState(initialBalls)
	const [access, setAccess] = useState(true)
	const [whatIWant, setWhatIWant] = useState('')

	const whatAllWants = useSelector(getWhatAllWantsSelector)
	const authUserName = useSelector(getAuthUserNameSelector)
	const IWant = whatAllWants.find(e => e.name === authUserName)?.whatHeWant

	const unreadyUsersCount =
		whatAllWants.length !== 0
			? whatAllWants.filter(e => e.whatHeWant === '').length
			: null

	const allOnTree = balls.every(ball => ball.x !== null && ball.y !== null)

	useEffect(() => {
		IWant && setWhatIWant(IWant)
	}, [whatAllWants])

	useEffect(() => {
		if (
			whatAllWants &&
			whatAllWants.length > 0 &&
			whatAllWants.find(e => e.name === authUserName)?.giftManId !== '' &&
			currentPage < 18
		) {
			setCurrentPage(18)
		}
	}, [whatAllWants, authUserName])

	useEffect(() => {}, [])

	useEffect(() => {
		unreadyUsersCount &&
			unreadyUsersCount > 0 &&
			setTimeout(() => dispatch(santaGetWhatAllWant()), 5000)
	}, [whatAllWants])

	const handleSantaLogic = async () => {
		if (whatIWant !== '') {
			await dispatch(santaSetWhatIWant(whatIWant.toLowerCase()))
		}
		if (unreadyUsersCount === 0) {
			setCurrentPage(currentPage + 1)
			setAccess(false)
			setTimeout(() => setAccess(true), 5000)
		} else {
			setTimeout(() => {
				dispatch(santaGetWhatAllWant())
			}, 5000)
		}
	}

	const nextButtonClick = () => {
		if (currentPage === 5) {
			if (firstAnswer.toLowerCase() === 'ветер') {
				setCurrentPage(currentPage + 1)
				setAccess(false)
				setTimeout(() => setAccess(true), 5000)
			} else {
				if (firstAnswerWrong) {
					setCurrentPage(19) // здесь страница где мы два раза ошиблись с ответом про ветер
				} else {
					setFirstAnswerWrong(true)
				}
			}
		} else if (currentPage === 18) {
			setCurrentPage(currentPage + 2)
			setAccess(false)
			setTimeout(() => setAccess(true), 5000)
		} else if (currentPage === 20) {
			setShow(false)
		} else if (currentPage === 10 || currentPage === 13) {
			setCurrentPage(currentPage + 1)
		} else if (currentPage === 17) {
			dispatch(santaGetMyGiftFriend())
			setCurrentPage(currentPage + 1)
			setAccess(false)
			setTimeout(() => setAccess(true), 5000)
		} else if (currentPage === 0) {
			handleSantaLogic()
		} else if (currentPage === 19) {
			setCurrentPage(7)
		} else {
			setCurrentPage(currentPage + 1)
			setAccess(false)
			setTimeout(() => setAccess(true), 5000)
		}
	}

	return (
		<>
			<Modal
				title={<span className='christmasHeading'>Christmas story</span>}
				open={show}
				className='christmasModal'
				footer={[
					<Button
						id='christmasNextPageButton'
						key='ok'
						type='primary'
						onClick={() => nextButtonClick()}
						className='christmas-button'
						disabled={
							(whatIWant === '' && currentPage === 0) ||
							(unreadyUsersCount &&
								unreadyUsersCount > 0 &&
								IWant !== '' &&
								currentPage === 0) ||
							(firstAnswer === '' && currentPage === 5) ||
							(secondAnswer === '' && currentPage === 6) ||
							(secondAnswer === '' && currentPage === 19) ||
							(coneCount !== 5 && currentPage === 11) ||
							(!allOnTree && currentPage === 14) ||
							!access
						}
					>
						{currentPage === 20 ? <CloseOutlined /> : <DoubleRightOutlined />}
					</Button>
				]}
				closable={false}
			>
				{currentPage === 0 && (
					<Page0
						whatIWant={whatIWant}
						setWhatIWant={setWhatIWant}
						IWant={IWant}
						unreadyUsersCount={unreadyUsersCount}
					/>
				)}
				{currentPage === 1 && <Page1 />}
				{currentPage === 2 && <Page2 />}
				{currentPage === 3 && <Page3 />}
				{currentPage === 4 && <Page4 />}
				{currentPage === 5 && (
					<Page5
						setFirstAnswer={setFirstAnswer}
						firstAnswerWrong={firstAnswerWrong}
					/>
				)}
				{currentPage === 6 && <Page6 setSecondAnswer={setSecondAnswer} />}
				{currentPage === 7 && <Page7 secondAnswer={secondAnswer} />}
				{currentPage === 8 && <Page8 />}
				{currentPage === 9 && <Page9 />}
				{currentPage === 10 && <Page10 />}
				{currentPage === 11 && (
					<Page11 coneCount={coneCount} setConeCount={setConeCount} />
				)}
				{currentPage === 12 && <Page12 />}
				{currentPage === 13 && <Page13 />}
				{currentPage === 14 && <Page14 balls={balls} setBalls={setBalls} />}
				{currentPage === 15 && <Page15 />}
				{currentPage === 16 && <Page16 />}
				{currentPage === 17 && <Page17 />}
				{currentPage === 18 && (
					<Page18 users={users} whatAllWants={whatAllWants} />
				)}
				{currentPage === 19 && <Page19 setSecondAnswer={setSecondAnswer} />}
				{currentPage === 20 && <Page20 />}
				<PreloadImages />
			</Modal>
		</>
	)
}

export default ChristmasStory
